import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Modal from './Modal';
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Owf, Afs } from './Drama';
import jinmaiprofile from './media/jinmaiprofile.png';
import linyiprofile from './media/linyiprofile.png';
import lilandiprofile from './media/lilandiprofile.png';
import { JinMai, LinYi, LiLandi } from './Idol';

const Boton = styled.button``;
const Contenido = styled.div``;

const Dramas = () => {
    const [eM1, cEM1] = useState(false);
    const [eM2, cEM2] = useState(false);
    const [eM3, cEM3] = useState(false);
    const [eM4, cEM4] = useState(false);
    
    useEffect(() => {
        AOS.init();
    }, [])
    
    return ( 
        <div className='dramas'>
            <Navbar/>
            <div className='drama-s'>
                <Owf/>
                    <Boton className='botonprofile' onClick={() => cEM1(!eM1)}><img src={jinmaiprofile} alt=""></img></Boton>
                    <Modal estado = {eM1} cambiarEstado = {cEM1}><Contenido className='contenidomodal'><JinMai/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM2(!eM2)}><img src={linyiprofile} alt=""></img></Boton>
                    <Modal estado = {eM2} cambiarEstado = {cEM2}><Contenido className='contenidomodal'><LinYi/></Contenido></Modal>
                    <div className='trailer'><iframe width="560" height="315" src="https://www.youtube.com/embed/fPsbfH8XIdw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
            </div>
            <div className='drama-s' id="bornpink">
                <Afs/>
                    <Boton className='botonprofile' onClick={() => cEM4(!eM4)}><img src={linyiprofile} alt=""></img></Boton>
                    <Modal estado = {eM4} cambiarEstado = {cEM4}><Contenido className='contenidomodal'><LinYi/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM3(!eM3)}><img src={lilandiprofile} alt=""></img></Boton>
                    <Modal estado = {eM3} cambiarEstado = {cEM3}><Contenido className='contenidomodal'><LiLandi/></Contenido></Modal>
            </div>
        </div>
    )
}

export default Dramas;


