import Footer from "./Footer";
import Navbar from "./Navbar";

function Contacto() {
    return (
      <div className="contacto">
        <div><Navbar/></div><br/>
        <div className="title1">Escríbenos</div>
        <div className="prgc3"><p>¿Hay algo que te gustaría decirnos o necesitas ponerte en contacto con nosotros?</p></div>
        <div className="prgc3"><p>En el siguiente apartado pues enviarnos un mensaje.</p></div>
        <div className="formulario2">
          <form>
            <label>
            <b>Nombre</b> (obligatorio)<br/>
            <input type="text" name="nombre" />
            </label><br/>
            <label>
            <b>Correo electrónico</b> (obligatorio)<br/>
            <input type="text" name="correo" />
            </label><br/>
            <label>
            <b>Mensaje</b><br/>
            <input type="text" name="pago" />
            </label><br/>
            <input className="submit" type="submit" value="Enviar mensaje" />
          </form>
        </div>
        <div><Footer/></div>
      </div>
    );
  }
  
  export default Contacto;