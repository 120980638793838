import React, { Component } from 'react';
import instagram from './media/instagram.svg'

class Jinmaic2 extends Component {
    render() {
        const {jinmai} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={jinmai.url} alt=""/></div>
            <div className='name'>{jinmai.nombre}</div>
            <div className='birth'> {jinmai.birth}</div> 
            <div className='bio'> {jinmai.bio}</div> 
            <a className='instagram' href={jinmai.instagram}><img src={instagram} width="35px" alt=""/></a>
        </div> }
}

class Linyic2 extends Component {
    render() {
        const {linyi} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={linyi.url} alt=""/></div>
            <div className='name'>{linyi.nombre}</div>
            <div className='birth'> {linyi.birth}</div> 
            <div className='bio'> {linyi.bio}</div> 
            <a className='instagram' href={linyi.instagram}><img src={instagram} width="35px" alt=""/></a>
        </div> }
}

class Yunac2 extends Component {
    render() {
        const {yuna} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={yuna.url} alt=""/></div>
            <div className='name'>{yuna.nombre}</div>
            <div className='birth'> {yuna.birth}</div> 
            <div className='bio'> {yuna.bio}</div>
            <a className='instagram' href={yuna.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Liac2 extends Component {
    render() {
        const {lia} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={lia.url} alt=""/></div>
            <div className='name'>{lia.nombre}</div>
            <div className='birth'> {lia.birth}</div> 
            <div className='bio'> {lia.bio}</div>
            <a className='instagram' href={lia.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Yejic2 extends Component {
    render() {
        const {yeji} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={yeji.url} alt=""/></div>
            <div className='name'>{yeji.nombre}</div>
            <div className='birth'> {yeji.birth}</div> 
            <div className='bio'> {yeji.bio}</div>
            <a className='instagram' href={yeji.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Chaeryeongc2 extends Component {
    render() {
        const {chaeryeong} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={chaeryeong.url} alt=""/></div>
            <div className='name'>{chaeryeong.nombre}</div>
            <div className='birth'> {chaeryeong.birth}</div> 
            <div className='bio'> {chaeryeong.bio}</div>
            <a className='instagram' href={chaeryeong.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Ryujinc2 extends Component {
    render() {
        const {ryujin} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={ryujin.url} alt=""/></div>
            <div className='name'>{ryujin.nombre}</div>
            <div className='birth'> {ryujin.birth}</div> 
            <div className='bio'> {ryujin.bio}</div>
            <a className='instagram' href={ryujin.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Lilandic2 extends Component {
    render() {
        const {lilandi} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={lilandi.url} alt=""/></div>
            <div className='name'>{lilandi.nombre}</div>
            <div className='birth'> {lilandi.birth}</div> 
            <div className='bio'> {lilandi.bio}</div>
            <a className='instagram' href={lilandi.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Jisooc2 extends Component {
    render() {
        const {jisoo} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={jisoo.url} alt=""/></div>
            <div className='name'>{jisoo.nombre}</div>
            <div className='birth'> {jisoo.birth}</div> 
            <div className='bio'> {jisoo.bio}</div>
            <a className='instagram' href={jisoo.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Jenniec2 extends Component {
    render() {
        const {jennie} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={jennie.url} alt=""/></div>
            <div className='name'>{jennie.nombre}</div>
            <div className='birth'> {jennie.birth}</div> 
            <div className='bio'> {jennie.bio}</div>
            <a className='instagram' href={jennie.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Rosec2 extends Component {
    render() {
        const {rose} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={rose.url} alt=""/></div>
            <div className='name'>{rose.nombre}</div>
            <div className='birth'> {rose.birth}</div> 
            <div className='bio'> {rose.bio}</div>
            <a className='instagram' href={rose.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Lisac2 extends Component {
    render() {
        const {lisa} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={lisa.url} alt=""/></div>
            <div className='name'>{lisa.nombre}</div>
            <div className='birth'> {lisa.birth}</div> 
            <div className='bio'> {lisa.bio}</div>
            <a className='instagram' href={lisa.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Nayeonc2 extends Component {
    render() {
        const {nayeon} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={nayeon.url} alt=""/></div>
            <div className='name'>{nayeon.nombre}</div>
            <div className='birth'> {nayeon.birth}</div> 
            <div className='bio'> {nayeon.bio}</div>
            <a className='instagram' href={nayeon.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Jeongyeonc2 extends Component {
    render() {
        const {jeongyeon} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={jeongyeon.url} alt=""/></div>
            <div className='name'>{jeongyeon.nombre}</div>
            <div className='birth'> {jeongyeon.birth}</div> 
            <div className='bio'> {jeongyeon.bio}</div>
            <a className='instagram' href={jeongyeon.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Momoc2 extends Component {
    render() {
        const {momo} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={momo.url} alt=""/></div>
            <div className='name'>{momo.nombre}</div>
            <div className='birth'> {momo.birth}</div> 
            <div className='bio'> {momo.bio}</div>
            <a className='instagram' href={momo.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Jihyoc2 extends Component {
    render() {
        const {jihyo} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={jihyo.url} alt=""/></div>
            <div className='name'>{jihyo.nombre}</div>
            <div className='birth'> {jihyo.birth}</div> 
            <div className='bio'> {jihyo.bio}</div>
            <a className='instagram' href={jihyo.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Sanac2 extends Component {
    render() {
        const {sana} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={sana.url} alt=""/></div>
            <div className='name'>{sana.nombre}</div>
            <div className='birth'> {sana.birth}</div> 
            <div className='bio'> {sana.bio}</div>
            <a className='instagram' href={sana.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Minac2 extends Component {
    render() {
        const {mina} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={mina.url} alt=""/></div>
            <div className='name'>{mina.nombre}</div>
            <div className='birth'> {mina.birth}</div> 
            <div className='bio'> {mina.bio}</div>
            <a className='instagram' href={mina.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Dahyunc2 extends Component {
    render() {
        const {dahyun} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={dahyun.url} alt=""/></div>
            <div className='name'>{dahyun.nombre}</div>
            <div className='birth'> {dahyun.birth}</div> 
            <div className='bio'> {dahyun.bio}</div>
            <a className='instagram' href={dahyun.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Chaeyoungc2 extends Component {
    render() {
        const {chaeyoung} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={chaeyoung.url} alt=""/></div>
            <div className='name'>{chaeyoung.nombre}</div>
            <div className='birth'> {chaeyoung.birth}</div> 
            <div className='bio'> {chaeyoung.bio}</div>
            <a className='instagram' href={chaeyoung.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

class Tzuyuc2 extends Component {
    render() {
        const {tzuyu} = this.props;
        return <div className='idol-c2'>
            <div className='idol-img'><img src={tzuyu.url} alt=""/></div>
            <div className='name'>{tzuyu.nombre}</div>
            <div className='birth'> {tzuyu.birth}</div> 
            <div className='bio'> {tzuyu.bio}</div>
            <a className='instagram' href={tzuyu.instagram}><img src={instagram} width="35px" alt=""/></a> 
        </div> }
}

export { Jinmaic2, Linyic2, Yunac2, Liac2, Yejic2, Chaeryeongc2, Ryujinc2, Lilandic2, Jisooc2, Jenniec2, Rosec2, Lisac2, Nayeonc2, Jeongyeonc2, Momoc2, Jihyoc2, Sanac2, Minac2, Dahyunc2, Chaeyoungc2, Tzuyuc2 };