import React, { Component } from 'react';
import { Owfc2, Afsc2 } from './DramaC2'

class Owfc1 extends Component {
    render() {return this.props.owf.map(owf => <Owfc2 owf={owf} key={owf.id}/>);}
}

class Afsc1 extends Component {
    render() {return this.props.afs.map(afs => <Afsc2 afs={afs} key={afs.id}/>);}
}

export { Owfc1, Afsc1 };