import './css/indexstyle.css';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import bljnk from './images/Bljink.png';
import libros from './images/MaxLibros.png';
// import asesor from './images/Mi-Gran-Asesor.png';
// import proyectos from './images/Proyectos.png';
import Footer from './Footer';
import proximamente from './images/Proximamente.png'

function Hero() {   

    const slideshow = useRef(null)
    
    const Transicion = () => {
        slideshow.current.style.transition = `1500ms ease-out all`;
        slideshow.current.style.transform = `translateY(-100%)`;       
      }

    return (
        <div className='hero'>
            <div onClick={Transicion} ref={slideshow} className='blureffect'>
                <div className="messageone">
                    <h3>Welcome</h3>
                    <p>Are you looking for solutions?</p>
                </div>
            </div>
            <Menu/>
            <div className="message">
                <h3>This is some of pieces</h3>
                <p>Take a look!</p>
            </div>
            <div className="gallery">
                <Link to="/bljnk"><div className='container1'><img className='piece' src={bljnk} alt=""/></div></Link>
                <Link to="/libros"><div className='container2'><img className='piece' src={libros} alt=""/></div></Link>
                <Link to="/dasttianplay"><div className='container3'><img className='piece' src={proximamente} alt=""/></div></Link>
                <Link to="/migranasesor"><div className='container4'><img className='piece' src={proximamente} alt=""/></div></Link>
            </div>
            <Footer/>
        </div>
    )
}

export default Hero