import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Modal from './Modal';
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Checkmate, Bornpink, Between } from './Musica';
import yunaprofile from './media/yunaprofile.png';
import liaprofile from './media/liaprofile.png';
import yejiprofile from './media/yejiprofile.png';
import chaeryeongprofile from './media/chaeryeongprofile.png';
import ryujinprofile from './media/ryujinprofile.png';
import jisooprofile from './media/jisooprofile.png';
import jennieprofile from './media/jennieprofile.png';
import roseprofile from './media/roseprofile.png';
import lisaprofile from './media/lisaprofile.png';
import nayeonprofile from './media/nayeonprofile.png';
import jeongyeonprofile from './media/jeongyeonprofile.png';
import momoprofile from './media/momoprofile.png';
import jihyoprofile from './media/jihyoprofile.png';
import sanaprofile from './media/sanaprofile.png';
import minaprofile from './media/minaprofile.png';
import dahyunprofile from './media/dahyunprofile.png';
import chaeprofile from './media/chaeprofile.png';
import tzuyuprofile from './media/tzuyuprofile.png';
import { Yuna, Lia, Yeji, Chaeryeong, Ryujin, Jisoo, Jennie, Rose, Lisa, Nayeon, Jeongyeon, Momo, Jihyo, Sana, Mina, Dahyun, Chaeyoung, Tzuyu } from './Idol';

const Boton = styled.button``;
const Contenido = styled.div``;

const Musica = () => {
    const [eM1, cEM1] = useState(false);
    const [eM2, cEM2] = useState(false);
    const [eM3, cEM3] = useState(false);
    const [eM4, cEM4] = useState(false);
    const [eM5, cEM5] = useState(false);
    const [eM6, cEM6] = useState(false);
    const [eM7, cEM7] = useState(false);
    const [eM8, cEM8] = useState(false);
    const [eM9, cEM9] = useState(false);
    const [eM10, cEM10] = useState(false);
    const [eM11, cEM11] = useState(false);
    const [eM12, cEM12] = useState(false);
    const [eM13, cEM13] = useState(false);
    const [eM14, cEM14] = useState(false);
    const [eM15, cEM15] = useState(false);
    const [eM16, cEM16] = useState(false);
    const [eM17, cEM17] = useState(false);
    const [eM18, cEM18] = useState(false);


    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='musica'>
            <Navbar/>
            <div className="musica-s">
                <Between/>
                    <Boton className='botonprofile' onClick={() => cEM10(!eM10)}><img src={nayeonprofile} alt=""></img></Boton>
                    <Modal estado = {eM10} cambiarEstado = {cEM10}><Contenido className='contenidomodal'><Nayeon/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM11(!eM11)}><img src={jeongyeonprofile} alt=""></img></Boton>
                    <Modal estado = {eM11} cambiarEstado = {cEM11}><Contenido className='contenidomodal'><Jeongyeon/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM12(!eM12)}><img src={momoprofile} alt=""></img></Boton>
                    <Modal estado = {eM12} cambiarEstado = {cEM12}><Contenido className='contenidomodal'><Momo/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM13(!eM13)}><img src={jihyoprofile} alt=""></img></Boton>
                    <Modal estado = {eM13} cambiarEstado = {cEM13}><Contenido className='contenidomodal'><Jihyo/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM14(!eM14)}><img src={sanaprofile} alt=""></img></Boton>
                    <Modal estado = {eM14} cambiarEstado = {cEM14}><Contenido className='contenidomodal'><Sana/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM15(!eM15)}><img src={minaprofile} alt=""></img></Boton>
                    <Modal estado = {eM15} cambiarEstado = {cEM15}><Contenido className='contenidomodal'><Mina/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM16(!eM16)}><img src={dahyunprofile} alt=""></img></Boton>
                    <Modal estado = {eM16} cambiarEstado = {cEM16}><Contenido className='contenidomodal'><Dahyun/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM17(!eM17)}><img src={chaeprofile} alt=""></img></Boton>
                    <Modal estado = {eM17} cambiarEstado = {cEM17}><Contenido className='contenidomodal'><Chaeyoung/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM18(!eM18)}><img src={tzuyuprofile} alt=""></img></Boton>
                    <Modal estado = {eM18} cambiarEstado = {cEM18}><Contenido className='contenidomodal'><Tzuyu/></Contenido></Modal> 
                    <div className='v-t'><iframe width="560" height="315" src="https://www.youtube.com/embed/k6jqx9kZgPM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div> 
            </div>
            <div className="musica-s">
                <Checkmate/>
                    <Boton className='botonprofile' onClick={() => cEM1(!eM1)}><img src={yunaprofile} alt=""></img></Boton>
                    <Modal estado = {eM1} cambiarEstado = {cEM1}><Contenido className='contenidomodal'><Yuna/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM2(!eM2)}><img src={liaprofile} alt=""></img></Boton>
                    <Modal estado = {eM2} cambiarEstado = {cEM2}><Contenido className='contenidomodal'><Lia/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM3(!eM3)}><img src={yejiprofile} alt=""></img></Boton>
                    <Modal estado = {eM3} cambiarEstado = {cEM3}><Contenido className='contenidomodal'><Yeji/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM4(!eM4)}><img src={chaeryeongprofile} alt=""></img></Boton>
                    <Modal estado = {eM4} cambiarEstado = {cEM4}><Contenido className='contenidomodal'><Chaeryeong/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM5(!eM5)}><img src={ryujinprofile} alt=""></img></Boton>
                    <Modal estado = {eM5} cambiarEstado = {cEM5}><Contenido className='contenidomodal'><Ryujin/></Contenido></Modal> 
                    <div className='v-t'><iframe width="560" height="315" src="https://www.youtube.com/embed/Hbb5GPxXF1w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div> 
            </div>
            <div className="musica-s">
                <Bornpink/>
                <Boton className='botonprofile' onClick={() => cEM6(!eM6)}><img src={jisooprofile} alt=""></img></Boton>
                    <Modal estado = {eM6} cambiarEstado = {cEM6}><Contenido className='contenidomodal'><Jisoo/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM7(!eM7)}><img src={jennieprofile} alt=""></img></Boton>
                    <Modal estado = {eM7} cambiarEstado = {cEM7}><Contenido className='contenidomodal'><Jennie/></Contenido></Modal>
                    <Boton className='botonprofile' onClick={() => cEM8(!eM8)}><img src={roseprofile} alt=""></img></Boton>
                    <Modal estado = {eM8} cambiarEstado = {cEM8}><Contenido className='contenidomodal'><Rose/></Contenido></Modal> 
                    <Boton className='botonprofile' onClick={() => cEM9(!eM9)}><img src={lisaprofile} alt=""></img></Boton>
                    <Modal estado = {eM9} cambiarEstado = {cEM9}><Contenido className='contenidomodal'><Lisa/></Contenido></Modal>
                    <div className='v-t'><iframe width="560" height="315" src="https://www.youtube.com/embed/gQlMMD8auMs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div> 
            </div>
        </div>
    )
}

export default Musica;