import './Libros.css';
import HeroSection from './components/HeroSection';

function Libros() {
  return (
    <div className="libros">
      <HeroSection/>
    </div>
  );
}

export default Libros;
