
import React, { useRef } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import itzy2 from './media/itzy2.jpg';
import owf3 from './media/owf3.png';
import arrowleft from './media/arrowleft.svg';
import arrowright from './media/arrowright.svg';
import styled from 'styled-components';
import Dramas from './Dramas';
import Musica from './MusicaM';

const SlideShow = () => {

    // slideshow animation----------------------------

    const slideshow = useRef(null);
    // const intervaloSlide = useRef(null);

    const Siguiente = () => {
      if(slideshow.current.children.length > 0) {
        const primerElemento = slideshow.current.children[0];
        slideshow.current.style.transition = `900ms ease-out all`;
        const sizeSlide = slideshow.current.children[0].offsetWidth;
        slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

        const Transicion = () => {
          slideshow.current.style.transition = 'none';
          slideshow.current.style.transform = `translateX(0)`;
          slideshow.current.appendChild(primerElemento);
          slideshow.current.removeEventListener('transitionend', Transicion);
          
        }
        slideshow.current.addEventListener('transitionend', Transicion)    
      }
     }
     
     const Anterior = () => {
      if(slideshow.current.children.length > 0) {
        const index = slideshow.current.children.length -1;
        const ultimoElemento = slideshow.current.children[index];
        slideshow.current.insertBefore(ultimoElemento, slideshow.current.firstChild);

        slideshow.current.style.transition = 'none';

        const sizeSlide = slideshow.current.children[0].offsetWidth;
        slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

        setTimeout(() => {
          slideshow.current.style.transition = `900ms ease-out all`;
          slideshow.current.style.transform = `translateX(0)`;
        })
        
      }
     }
    
    //  useEffect(() => {
    //    intervaloSlide.current = setInterval(() => {
    //       Siguiente()
    //     }, 9000);
    //       slideshow.current.addEventListener('mouseenter', () => {
    //         clearInterval(intervaloSlide.current);
    //       });
    //       slideshow.current.addEventListener('mouseleave', () => {
    //         intervaloSlide.current = setInterval(() => {
    //           Siguiente()
    //         }, 9000);
    //       });
    //  }, []);

     return (
      <div className='slide-show'>
        <Contenedor>
          <ContenedorSlide ref={slideshow}>
            <Slide>
              <Link to="/musica"><img src={itzy2} alt="..."/></Link>
              <Routes><Route path="/musica" element={<div><Musica/></div>}/></Routes>
              <TextSlide>
                <p></p>
              </TextSlide>
            </Slide>
            <Slide>
              <Link to="/dramas"><img src={owf3} alt="..."/></Link>
              <Routes><Route path="/dramas" element={<div><Dramas/></div>}/></Routes>
              <TextSlide>
                <p></p>
              </TextSlide>
            </Slide>
            <Slide>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/k6jqx9kZgPM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <TextSlide>
                <p></p>
              </TextSlide>
            </Slide>
          </ContenedorSlide>  
          <Controles>
            <Boton onClick={Anterior}><img className='boton' src={arrowleft} alt=''></img></Boton>
            <Boton derecho onClick={Siguiente}><img className='boton' src={arrowright} alt=''></img></Boton>
          </Controles>
        </Contenedor>
      </div>
      
     );

  }

  const Contenedor = styled.div`
    position: relative;
    overflow: hidden;
    max-width: 1500px;
  `;
  
  const ContenedorSlide = styled.div`
    display: flex;
    flex-wrap: nowrap;
    `;
    
  const Slide = styled.div`
    min-width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 850px;
    img {
      width: 100%;
      vertical align: top;
      border-radius: 11px;
    }
    iframe {
      width: 100%;
      vertical align: top;
      border-radius: 11px;
    }
  `;

  const TextSlide = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
  `;

  const Controles = styled.div`
    width: 100%;
    height: 100%;
  `;

  const Boton = styled.div`
    top: 45%;
    margin: 0px 10px;
    position: absolute;
    pointer-events: all;
    background: none;
    border: none;
    cursor: pointer;
    img {
      width: 40px;
    }
    img:hover {
      border-radius: 50%;
      background: white;
      box-shadow: 0px 0px 0px whitesmoke;
    }

    ${props => props.derecho ? 'right: 0': 'left: 0'}
  `;

  
export default SlideShow;