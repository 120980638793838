import React, { Component } from 'react';
import jinmai from './../idols/jinmai.json';
import linyi from './../idols/linyi.json';
import yuna from './../idols/yuna.json';
import lia from './../idols/lia.json';
import yeji from './../idols/yeji.json';
import chaeryeong from './../idols/chaeryeong.json';
import ryujin from './../idols/ryujin.json';
import lilandi from './../idols/lilandi.json';
import jisoo from './../idols/jisoo.json';
import jennie from './../idols/jennie.json';
import rose from './../idols/rose.json';
import lisa from './../idols/lisa.json';
import nayeon from './../idols/nayeon.json';
import jeongyeon from './../idols/jeongyeon.json';
import momo from './../idols/momo.json';
import jihyo from './../idols/jihyo.json';
import sana from './../idols/sana.json';
import mina from './../idols/mina.json';
import dahyun from './../idols/dahyun.json';
import chaeyoung from './../idols/chaeyoung.json';
import tzuyu from './../idols/tzuyu.json';
import { Jinmaic1, Linyic1, Yunac1, Liac1, Yejic1, Chaeryeongc1, Ryujinc1, Lilandic1, Jisooc1, Jenniec1, Rosec1, Lisac1, 
        Nayeonc1, Jeongyeonc1, Momoc1, Jihyoc1, Sanac1, Minac1, Dahyunc1, Chaeyoungc1, Tzuyuc1 } from './IdolC1'

class JinMai extends Component {
    state = {jinmai: jinmai}
    render () {return <div className="idol-c1"><Jinmaic1 jinmai={this.state.jinmai}/></div>}
}

class LinYi extends Component {
    state = {linyi: linyi}
    render () {return <div className="idol-c1"><Linyic1 linyi={this.state.linyi}/></div>}
}

class Yuna extends Component {
    state = {yuna: yuna}
    render () {return <div className="idol-c1"><Yunac1 yuna={this.state.yuna}/></div>}
}

class Lia extends Component {
    state = {lia: lia}
    render () {return <div className="idol-c1"><Liac1 lia={this.state.lia}/></div>}
}

class Yeji extends Component {
    state = {yeji: yeji}
    render () {return <div className="idol-c1"><Yejic1 yeji={this.state.yeji}/></div>}
}

class Chaeryeong extends Component {
    state = {chaeryeong: chaeryeong}
    render () {return <div className="idol-c1"><Chaeryeongc1 chaeryeong={this.state.chaeryeong}/></div>}
}

class Ryujin extends Component {
    state = {ryujin: ryujin}
    render () {return <div className="idol-c1"><Ryujinc1 ryujin={this.state.ryujin}/></div>}
}

class LiLandi extends Component {
    state = {lilandi: lilandi}
    render () {return <div className="idol-c1"><Lilandic1 lilandi={this.state.lilandi}/></div>}
}

class Jisoo extends Component {
    state = {jisoo: jisoo}
    render () {return <div className="idol-c1"><Jisooc1 jisoo={this.state.jisoo}/></div>}
}

class Jennie extends Component {
    state = {jennie: jennie}
    render () {return <div className="idol-c1"><Jenniec1 jennie={this.state.jennie}/></div>}
}

class Rose extends Component {
    state = {rose: rose}
    render () {return <div className="idol-c1"><Rosec1 rose={this.state.rose}/></div>}
}

class Lisa extends Component {
    state = {lisa: lisa}
    render () {return <div className="idol-c1"><Lisac1 lisa={this.state.lisa}/></div>}
}

class Nayeon extends Component {
    state = {nayeon: nayeon}
    render () {return <div className="idol-c1"><Nayeonc1 nayeon={this.state.nayeon}/></div>}
}

class Jeongyeon extends Component {
    state = {jeongyeon: jeongyeon}
    render () {return <div className="idol-c1"><Jeongyeonc1 jeongyeon={this.state.jeongyeon}/></div>}
}

class Momo extends Component {
    state = {momo: momo}
    render () {return <div className="idol-c1"><Momoc1 momo={this.state.momo}/></div>}
}

class Jihyo extends Component {
    state = {jihyo: jihyo}
    render () {return <div className="idol-c1"><Jihyoc1 jihyo={this.state.jihyo}/></div>}
}

class Sana extends Component {
    state = {sana: sana}
    render () {return <div className="idol-c1"><Sanac1 sana={this.state.sana}/></div>}
}

class Mina extends Component {
    state = {mina: mina}
    render () {return <div className="idol-c1"><Minac1 mina={this.state.mina}/></div>}
}

class Dahyun extends Component {
    state = {dahyun: dahyun}
    render () {return <div className="idol-c1"><Dahyunc1 dahyun={this.state.dahyun}/></div>}
}

class Chaeyoung extends Component {
    state = {chaeyoung: chaeyoung}
    render () {return <div className="idol-c1"><Chaeyoungc1 chaeyoung={this.state.chaeyoung}/></div>}
}

class Tzuyu extends Component {
    state = {tzuyu: tzuyu}
    render () {return <div className="idol-c1"><Tzuyuc1 tzuyu={this.state.tzuyu}/></div>}
}

export { JinMai, LinYi, Yuna, Lia, Yeji, Chaeryeong, Ryujin, LiLandi, Jisoo, Jennie, Rose, Lisa, Nayeon, Jeongyeon, Momo, Jihyo, Sana, Mina, Dahyun, Chaeyoung, Tzuyu };