import { Link } from 'react-router-dom';

export default function Slidebar({close}) {
    return (
        <div className='slidebar' onClick={close}>
            <Link to="/dramas">
                <button data-text="Awesome" className="button">
                    <span className="actual-text">&nbsp;dramas&nbsp;</span>
                    <span className="hover-text" aria-hidden="true">&nbsp;dramas&nbsp;</span>
                </button>
                    </Link>
            <Link to="/musica">
                <button data-text="Awesome" className="button">
                    <span className="actual-text">&nbsp;musica&nbsp;</span>
                    <span className="hover-text" aria-hidden="true">&nbsp;musica&nbsp;</span>
                </button>
            </Link>
            <div className='social-media'>
                <a href="/"><div className="fa-brands fa-instagram"></div></a>
                <a href="/"><div className="fa-brands fa-facebook"></div></a>
                <a href="/"><div className="fa-brands fa-twitter"></div></a>
            </div>
        </div>
    );
}