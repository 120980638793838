import React from "react";
import styled from "styled-components";
import close from './close.svg';

const Overlay = styled.div``;
const ContenedorModal = styled.div``;
const BotonClose = styled.button``;

const Modal = ({children, estado, cambiarEstado}) => {
    return (
        <>
            {estado &&
                <Overlay className="overlay">
                    <ContenedorModal className="contenedor-modal">
                        <BotonClose className="close-modal" onClick={() => cambiarEstado(false)}><img src={close} alt=""></img></BotonClose>
                        {children}
                    </ContenedorModal>
                </Overlay>
            }
        </>
    )
}

export default Modal;



