import React, { Component } from 'react';
import { Checkmatec2, Bornpinkc2, Betweenc2 } from './MusicaC2';

class Checkmatec1 extends Component {
    render() {return this.props.checkmate.map(checkmate => <Checkmatec2 checkmate={checkmate} key={checkmate.id}/>);}
}

class Bornpinkc1 extends Component {
    render() {return this.props.bornpink.map(bornpink => <Bornpinkc2 bornpink={bornpink} key={bornpink.id}/>);}
}

class Betweenc1 extends Component {
    render() {return this.props.between.map(between => <Betweenc2 between={between} key={between.id}/>);}
}

export { Checkmatec1, Bornpinkc1, Betweenc1 } ;