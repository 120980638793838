import './css/aboutstyle.css';
import Menu from './Menu';
import Footer from './Footer';

function About() {
    return (
        <div>
            <Menu/>
            
            <div className="container_about">
            <div className="item1">
                <h2>Picture</h2>
            </div>
            
            <div className="item2">
                <h1>David Cortes</h1>
                <div className="textabout">
                    <div>
                        <h3>I believe that every application, no matter the device, should be intuitive and practical. Turning the difficult into something simple is what I like about UX design.</h3> 
                    </div>
                    <div>
                        <p>I started working as a field support engineer. My task was to calibrate equipment in the quality laboratories of different companies. This activity gave me the opportunity to get closer not only to the users of the equipment, but also to the researchers who were looking to improve that equipment. Then I worked as technical support for citizen attention modules. Here I began to know the processes of implementation of new systems in order to reduce service times and facilitate the work of users. Later I changed area again participating in the implementation of applications, but now, in a web environment. This last activity led me to the area of programming and later to discover what is the design focused on user experience.
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="item3">
                <p></p>
            </div>
            
            <div className="item4">
                <h4>Skills</h4>
                <ul>
                    <li>HTML5 + CSS + JavaScript</li>
                    <li>Git + GitHub</li>
                    <li>Figma</li>
                    <li>Illustrator</li>
                    <li>Photoshop</li>
                    <li>InDesing</li>
                    <li>PremierPro</li>
                    <li>OBS Studio</li>
                    <li>Microsoft Office</li>
                </ul>
            </div>
            
        </div>
        <Footer/>
    </div>
    )
}

export default About