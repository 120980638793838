import React, { Component } from 'react';

class Checkmatec2 extends Component {
    render() {
        const {checkmate} = this.props;
        return <div>
            <div className='img'><img src={checkmate.url} alt=""/></div>
            <div className='title'>{checkmate.title}</div>
            <div className='content'> {checkmate.content}</div> 
        </div>  
    }
}

class Bornpinkc2 extends Component {
    render() {
        const {bornpink} = this.props;
        return <div>
            <div className='img'><img src={bornpink.url} alt=""/></div>
            <div className='title'>{bornpink.title}</div>
            <div className='content'> {bornpink.content}</div> 
        </div>  
    }
}

class Betweenc2 extends Component {
    render() {
        const {between} = this.props;
        return <div>
            <div className='img'><img src={between.url} alt=""/></div>
            <div className='title'>{between.title}</div>
            <div className='content'> {between.content}</div> 
        </div>  
    }
}

export { Checkmatec2, Bornpinkc2, Betweenc2 } ;