import { Link } from 'react-router-dom';
import logo from './images/1x/icon.png';
import './css/style.css';

function Menu() {
    return  (
        <div className="container_head">
            <div className="logo">
                <img src={logo} alt=""/>
            </div>
            <div className="menu">
                <Link className='link' to="/">Work</Link>
                <Link className='link' to="/about">About</Link>
                <Link className='link' to="/contact">Contact</Link>
            </div>
        </div>
    )
}

export default Menu