import Footer from "./Footer";
import Navbar from "./Navbar";
import rb from './media/rb.jpg'

function HeroSection() {
    return (
      <div className="herolib">
        <div><Navbar/></div><br/>
        <div className="title1">¿Quién es Max Calvillo?</div>
        <div className="prg"><p>Con más de 50 años de experiencia, Max Calvillo Bonales, consultor de empresas en materia de desarrollo organizacional; especializado en administración por calidad total y certificación de empresas ISO 9001:2008. Ingeniero Quimico de profesion y ex futbolista, conoce perfectamente el mundo de los negocios y como se manejan las diferentes industrias.</p></div>
        <div className="prg"><p>En 2022 lanza su primer libro: «Campeones Mundiales de Fútbol -Estrategias-«. Con el pretende enseñar, de una manera diferente, cual es el correcto camino hacia el liderazgo; usando como ejemplo a los diferentes equipos que han conquistado el máximo campeonato de este deporte. Cualquiera que sea tu posición dentro de la industria, si eres responsable de tomar decisiones, debes conocer el contenido de este libro. </p></div>
        <div className="prg"><p>Max Calvillo, te entrega una metodología desarrollada y probada con la cual ha ayudado a diferentes empresas nacionales e internacionales, a resolver sus problemas de baja productividad y lento crecimiento.</p></div>
        <img alt=""></img>
        <div className="frase1">
          <p>"Todos, sin excepción alguna, formamos parte un equipo de trabajo, y en nosotros esta dirigirlo en el camino correcto."</p>
        </div>
        <div className="autor"><p>Max Calvillo B.</p></div>
        <div className="main-carousel">
          <div className="carouselitem">
            <div id="carouselExampleIndicators" className="carousel slide">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={rb} class="d-block w-100" alt="..."></img>
                </div>
                <div className="carousel-item">
                  <img src={rb} class="d-block w-100" alt="..."></img>
                </div>
                <div className="carousel-item">
                  <img src={rb} class="d-block w-100" alt="..."></img>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        
        <div className="title2">¿Por qué necesitas leer este libro?</div>
        <div className="prg"><p>Este libro incluye una metodología práctica que te guía, paso por paso, para mejorar la productividad de tu empresa; enseñando como deben enfocarse cada uno de los diferentes departamentos y sus equipos de trabajo. Te ayuda a resolver problemas que abarcan desde la falta de productividad y un difícil ambiente laboral; hasta la organización y gestión de grandes proyectos.</p></div>
        <div className="prg"><p>Este libro te da las herramientas para pasar de un jefe de departamento, gerente o director ejecutivo, a ser un verdadero líder en el desarrollo de equipos de trabajo con un alto nivel competitivo lo cual mejora sustancialmente la productividad y la eficiencia. No volverás a ser el mismo, entenderás la importancia del crecimiento sostenible y como se logra que un departamento pueda volverse 100% auto-suficiente.</p></div>
        <div className="midcm">
          <div className="btn-cont">
            <button>Me interesa, deseo conocer más</button>
          </div>
        </div>
        <div><Footer/></div>
      </div>
    );
  }
  
  export default HeroSection;