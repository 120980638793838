import React, { Component } from 'react';

class Owfc2 extends Component {
    render() {
        const {owf} = this.props;
        return <div>
            <div className='img'><img src={owf.url} alt=""/></div>
            <div className='title'>{owf.title}</div>
            <div className='content'> {owf.content}</div> 
        </div>  
    }
}

class Afsc2 extends Component {
    render() {
        const {afs} = this.props;
        return <div>
            <div className='img'><img src={afs.url} alt=""/></div>
            <div className='title'>{afs.title}</div>
            <div className='content'> {afs.content}</div> 
        </div>  
    }
}

export { Owfc2, Afsc2 };