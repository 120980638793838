import './css/contactstyle.css';
import Menu from './Menu';
import Footer from './Footer';

function Contact() {
    return (
        <div>
            <Menu/>
            <div className="container_contact">
            <div className="item1">
                <h1>Get in Touch</h1>
                <h3>Available for questions, collaborations, projects & coffee.</h3> 
            </div>
            
            <div className="item2">
                <p></p>
            </div>
            
            <div className="item3">
                <p></p>
            </div>
            
            <div className="item4">
                <form action="resourses/form.php" method="post">
                    <fieldset>
                        <label for="first-name">First Name *<input id="first-name" name="first-name" type="text" required /></label>  

                        <label for="last-name">Last Name *<input id="last-name" name="last-name" type="text" required /></label>
                        
                        <label for="email">Email Adress *<input id="email" name="email" type="email" required /></label>  

                        <label for="subject">Subject <input id="subject" name="subject" type="text" /></label>  

                        <label for="message">Message <textarea id="message" name="message" rows="3" cols="30" placeholder=""></textarea>
                        </label>
                        
                        <input type="submit" value="Submit" />
                    </fieldset>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
    )
}

export default Contact