import React, { Component } from 'react';
import { Jinmaic2, Linyic2, Yunac2, Liac2, Yejic2, Chaeryeongc2, Ryujinc2, Lilandic2, Jisooc2, Jenniec2, Rosec2, Lisac2,
        Nayeonc2, Jeongyeonc2, Momoc2, Jihyoc2, Sanac2, Minac2, Dahyunc2, Chaeyoungc2, Tzuyuc2 } from './IdolC2';

class Jinmaic1 extends Component {
    render() {return this.props.jinmai.map(jinmai => <Jinmaic2 jinmai={jinmai} key={jinmai.id}/>);}
}

class Linyic1 extends Component {
    render() {return this.props.linyi.map(linyi => <Linyic2 linyi={linyi} key={linyi.id}/>);}
}

class Yunac1 extends Component {
    render() {return this.props.yuna.map(yuna => <Yunac2 yuna={yuna} key={yuna.id}/>);}
}

class Liac1 extends Component {
    render() {return this.props.lia.map(lia => <Liac2 lia={lia} key={lia.id}/>);}
}

class Yejic1 extends Component {
    render() {return this.props.yeji.map(yeji => <Yejic2 yeji={yeji} key={yeji.id}/>);}
}

class Chaeryeongc1 extends Component {
    render() {return this.props.chaeryeong.map(chaeryeong => <Chaeryeongc2 chaeryeong={chaeryeong} key={chaeryeong.id}/>);}
}

class Ryujinc1 extends Component {
    render() {return this.props.ryujin.map(ryujin => <Ryujinc2 ryujin={ryujin} key={ryujin.id}/>);}
}

class Lilandic1 extends Component {
    render() {return this.props.lilandi.map(lilandi => <Lilandic2 lilandi={lilandi} key={lilandi.id}/>);}
}

class Jisooc1 extends Component {
    render() {return this.props.jisoo.map(jisoo => <Jisooc2 jisoo={jisoo} key={jisoo.id}/>);}
}

class Jenniec1 extends Component {
    render() {return this.props.jennie.map(jennie => <Jenniec2 jennie={jennie} key={jennie.id}/>);}
}

class Rosec1 extends Component {
    render() {return this.props.rose.map(rose => <Rosec2 rose={rose} key={rose.id}/>);}
}

class Lisac1 extends Component {
    render() {return this.props.lisa.map(lisa => <Lisac2 lisa={lisa} key={lisa.id}/>);}
}

class Nayeonc1 extends Component {
    render() {return this.props.nayeon.map(nayeon => <Nayeonc2 nayeon={nayeon} key={nayeon.id}/>);}
}

class Jeongyeonc1 extends Component {
    render() {return this.props.jeongyeon.map(jeongyeon => <Jeongyeonc2 jeongyeon={jeongyeon} key={jeongyeon.id}/>);}
}

class Momoc1 extends Component {
    render() {return this.props.momo.map(momo => <Momoc2 momo={momo} key={momo.id}/>);}
}

class Jihyoc1 extends Component {
    render() {return this.props.jihyo.map(jihyo => <Jihyoc2 jihyo={jihyo} key={jihyo.id}/>);}
}

class Sanac1 extends Component {
    render() {return this.props.sana.map(sana => <Sanac2 sana={sana} key={sana.id}/>);}
}

class Minac1 extends Component {
    render() {return this.props.mina.map(mina => <Minac2 mina={mina} key={mina.id}/>);}
}

class Dahyunc1 extends Component {
    render() {return this.props.dahyun.map(dahyun => <Dahyunc2 dahyun={dahyun} key={dahyun.id}/>);}
}

class Chaeyoungc1 extends Component {
    render() {return this.props.chaeyoung.map(chaeyoung => <Chaeyoungc2 chaeyoung={chaeyoung} key={chaeyoung.id}/>);}
}

class Tzuyuc1 extends Component {
    render() {return this.props.tzuyu.map(tzuyu => <Tzuyuc2 tzuyu={tzuyu} key={tzuyu.id}/>);}
}

export { Jinmaic1, Linyic1, Yunac1, Liac1, Yejic1, Chaeryeongc1, Ryujinc1, Lilandic1, Jisooc1, Jenniec1, Rosec1, Lisac1, Nayeonc1, Jeongyeonc1, Momoc1, Jihyoc1, Sanac1, Minac1, Dahyunc1, Chaeyoungc1, Tzuyuc1 };


