import './css/style.css';

function Footer() {
    return (
        <div className="container_footer">
            <div className="contact">
                <p>© David Cortes 2023 </p>
                <p>| UX designer & Web developer</p>
                <a href="mailto:davids.cortes@outlook.com">| davids.cortes@outlook.com </a>
            </div>
            <div className="social">
                <i className="fa-brands fa-twitter fa-lg"></i>
                <i className="fa-brands fa-linkedin fa-lg"></i>
                <i className="fa-brands fa-instagram fa-lg"></i>
            </div>
        </div>
    )
}

export default Footer