import './MiGranAsesor.css';
import proximamente from '../images/Proximamente.png'

function MiGranAsesor() {
    return (
        <div className='migranasesor'>
                <img src={proximamente} alt=''></img>
        </div>
    )
}

export default MiGranAsesor