import './Appbljnk.css';
import './indexbljnk.css';
import Navbar from './Components/Navbar';
import HeroSection from './Components/HeroSection';

function Bljnk () {
  return (
    <div className="bljnk-page">
        <Navbar/>
        <div className='main container'>
          <HeroSection/>
        </div>
    </div>
  );
}     

export default Bljnk;


