import React, { Component } from 'react';
import checkmate from './../musican/checkmate.json';
import bornpink from './../musican/bornpink.json';
import between from './../musican/between1&2.json';
import { Checkmatec1, Bornpinkc1, Betweenc1 } from './MusicaC1';

class Checkmate extends Component {
    state = {checkmate: checkmate}
    render () {return <div className="musican"><Checkmatec1 checkmate={this.state.checkmate}/></div>}
}

class Bornpink extends Component {
    state = {bornpink: bornpink}
    render () {return <div className="musican"><Bornpinkc1 bornpink={this.state.bornpink}/></div>}
}

class Between extends Component {
    state = {between: between}
    render () {return <div className="musican"><Betweenc1 between={this.state.between}/></div>}
}

export { Checkmate, Bornpink, Between } ;