import { Link } from 'react-router-dom';

export default function Slidebar({close}) {
    return (
        <div className='slidebarlib' onClick={close}>
            <Link to="/contacto"><div className='btns1'>Contacto</div></Link>
            <Link to="/comprar"><div className='btns2'>Comprar</div></Link>
            <div className='social-media'>
                <div className="fa-brands fa-facebook"></div>
            </div>
        </div>
    );
}