import Footer from "./Footer";
import Navbar from "./Navbar";

function Comprar() {
    return (
      <div className="comprar">
        <div><Navbar/></div><br/>
        <div className="title1">Como adquirir el libro</div>
        <div className="prgc2"><p>Nuestro libro tiene un costo por promoción de $398.00. Si deseas adquirir un ejemplar contamos con diferentes opciones.</p></div>
        <div className="gridcontainer">
          <div className="griditem1">Paypal</div>
          <div className="griditem2">Puedes transferirnos mediante pago seguro por Paypal</div>
          <div className="griditem1">Transferencia</div>
          <div className="griditem2">También puedes enviarnos una transferencia bancaria.</div>
          <div className="griditem1">Efectivo</div>
          <div className="griditem2">Puedes hacer tu deposito en efectivo a nuestra cuenta, o bien pagar al recoger tu libro en nuestro domicilio.</div>
        </div>
        <div className="prgc2"><p>En caso de decidirte, por favor, escríbenos mediante el siguiente formulario y nosotros nos pondremos en contacto contigo lo antes posible. También puedes escribirnos mediante nuestra pagina de Facebook.</p></div>
        <div className="title2">Quiero solicitar un libro</div>
        <div className="formulario1">
          <form>
            <label>
            <b>Nombre</b> (obligatorio)<br/>
            <input type="text" name="nombre" />
            </label><br/>
            <label>
            <b>Correo electrónico</b> (obligatorio)<br/>
            <input type="text" name="correo" />
            </label><br/>
            <label>
            <b>Método de pago que desea utilizar</b> (obligatorio)<br/>
            <input type="text" name="pago" />
            </label><br/>
            <input className="submit" type="submit" value="Enviar solicitud" />
          </form>
        </div>
        <div className="qna">
          <div className="qna-cont">
            <div className="title2">Preguntas frecuentes</div>
            <h5>¿Porque utilizan este método de compra?</h5>
            <p>Sabemos que la situación actual hace que cada peso cuente, por eso evitamos comisiones de intermediarios, realizando las transacciones de manera directa. <b>A cambio de eso, todos nuestros libros van autografiados y con dedicatoria.</b></p>
            <h5>¿Donde están ubicados?</h5>
            <p>Nuestro domicilio se encuentra en la ciudad de Querétaro, en la colonia Álamos 2da Sección.</p>
            <h5>¿Realizas envíos a todo México o solo vendes en Querétaro?</h5>
            <p>Podemos enviar a todo México y nuestros clientes tienen la capacidad de escoger diferentes métodos de envió (los costos también varían).</p>
            <h5>¿Como puedo ponerme en contacto con ustedes?</h5>
            <p>Puedes escribirnos en nuestra pagina de contacto o directamente a nuestra pagina oficial en Facebook (links en la parte de abajo).</p>
          </div>
        </div>
        <div><Footer/></div>
      </div>
    );
  }
  
  export default Comprar;