import { Link } from 'react-router-dom';
import { useState } from 'react';
import Slidebar from './Slidebar';
import more from './media/more.png'

function Navbar() {

  const [showSlidebar, setShowSlidebar] = useState(false)

    function closeSlidebar() {
        setShowSlidebar(false)
    }

    return (
      <div className="navlib">
        <Link to="/libros"><div className='name'>Sitio del escritor Max Calvillo B</div></Link>
        <div className='navlib-menu'>
          <Link to="/contacto"><div className='btn1'>Contacto</div></Link>
          <Link to="/comprar"><div className='btn2'>Comprar</div></Link>
        </div>
        <div onClick={() => setShowSlidebar(!showSlidebar)} className= {showSlidebar ? "slide-btn active" : "slide-btn"}>
          <img src={more} alt=""/>
        </div>
        {showSlidebar && <Slidebar close={closeSlidebar}/>}
      </div>
    );
  }
  
  export default Navbar;

  

