import hot from './hot.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import SlideShow from './slideshow';

export default function HeroSection () {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="hero-section">
            <SlideShow/>
            <div className="flex-container1">
                <div className="flex-item11" data-aos="fade-down-right" data-aos-delay="50" data-aos-duration="1000"><iframe width="560" height="315" src="https://www.youtube.com/embed/Hbb5GPxXF1w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                <div className="flex-item12" data-aos="fade-down-left" data-aos-delay="50" data-aos-duration="1000"><div className='title-hero'>Se estrena el nuevo video de Itzy 'Sneakers'!</div><div className='content-hero'>Con un concepto visual totalmente nuevo llega el esperado video de Itzy titulado 'Sneakers', este comeback combina elegancia y deportividad con una agradable melodía.</div></div>
            </div>
            <div className='hot'data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000"><div className='title-hot'>Trending Songs</div><img src={hot} width={30} height={30} alt=""></img></div>
            <div className="flex-container2" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1DX1LU4UHKqdtg?utm_source=generator" title='Spotify' width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
            
        </div>
    )
}

