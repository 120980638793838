import { useState } from 'react';
import Slidebar from './Slidebar';
import { Link } from 'react-router-dom';
import Lota from './media/Lota.png'

export default function Navbar() {

    const [showSlidebar, setShowSlidebar] = useState(false)

    function closeSlidebar() {
        setShowSlidebar(false)
    }

    return (
        <>
            <div className="nav-bar">
                <img className='logo-img' src={Lota} alt=""/>
                <Link to="/bljnk">
                        <button data-text="Awesome" className="bljnk-button">
                            <span className="actual-text">&nbsp;bljnk&nbsp;</span>
                            <span className="hover-text" aria-hidden="true">&nbsp;bljnk&nbsp;</span>
                        </button>
                </Link>
                <div className='nav-menu'>
                    <Link to="/dramas">
                        <button data-text="Awesome" className="button">
                            <span className="actual-text">&nbsp;dramas&nbsp;</span>
                            <span className="hover-text" aria-hidden="true">&nbsp;dramas&nbsp;</span>
                        </button>
                    </Link>
                    <Link to="/musica">
                        <button data-text="Awesome" className="button">
                            <span className="actual-text">&nbsp;musica&nbsp;</span>
                            <span className="hover-text" aria-hidden="true">&nbsp;musica&nbsp;</span>
                        </button>
                    </Link>
                </div>
                <div className='social-bar'>
                    <a href="/"><div className="fa-brands fa-instagram"></div></a>
                    <a href="/"><div className="fa-brands fa-facebook"></div></a>
                    <a href="/"><div className="fa-brands fa-twitter"></div></a>
                </div>
                <div onClick={() => setShowSlidebar(!showSlidebar)} className= {showSlidebar ? "slide-btn active" : "slide-btn"}>
                    <img src={Lota} alt=""/>
                </div>
            </div>
            {showSlidebar && <Slidebar close={closeSlidebar}/>}
            
        </>
    );
}