import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import About from './components/About';
import Contact from './components/Contact';
import Libros from './components/libros/Libros';
import Comprar from './components/libros/components/Comprar';
import Contacto from './components/libros/components/Contacto';
import Bljnk from './components/bljnk/bljnk';
import Dramas from './components/bljnk/Components/Dramas';
import Musica from './components/bljnk/Components/MusicaM';
import DasttianPlay from './components/dasttianplay/DasttianPlay';
import MiGranAsesor from './components/migranasesor/MiGranAsesor';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <div className='main'>
          <Routes>
          <Route path="/" element={<div><Hero/></div>}/>
            <Route path="/about" element={<div><About/></div>}/>
            <Route path="/contact" element={<div><Contact/></div>}/>
            <Route path="/libros" element={<div><Libros/></div>}/>
            <Route path="/comprar" element={<div><Comprar/></div>}/>
            <Route path="/contacto" element={<div><Contacto/></div>}/>
            <Route path="/bljnk" element={<div><Bljnk/></div>}/>
            <Route path="/dramas" element={<div><Dramas/></div>}/>
            <Route path="/musica" element={<div><Musica/></div>}/>
            <Route path="/dasttianplay" element={<div><DasttianPlay/></div>}/>
            <Route path="/migranasesor" element={<div><MiGranAsesor/></div>}/>
          </Routes>
          </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
