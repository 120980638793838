import React, { Component } from 'react';
import owf from './../dramas/owf.json';
import afs from './../dramas/afs.json';
import { Owfc1, Afsc1 } from './DramaC1'

class Owf extends Component {
    state = {owf: owf}
    render () {return <div className="drama"><Owfc1 owf={this.state.owf}/></div>}
}

class Afs extends Component {
    state = {afs: afs}
    render () {return <div className="drama"><Afsc1 afs={this.state.afs}/></div>}
}

export { Owf, Afs };