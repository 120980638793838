import { Link } from 'react-router-dom';
import facebook from './media/facebook.svg'

function Footer() {
    return (
      <div className="footer">
        <img src={facebook} alt="facebook"></img><br/>
        <Link to="/comprar"><div className='btnf'>Comprar</div></Link>
        <Link to="/contacto"><div className='btnf'>Contacto</div></Link>
        <div>© 2023  MaxLibros.com</div>
      </div>
    );
  }
  
  export default Footer;